import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../actions';
import {
  Button,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';

const mapStateToProps = state => ({
  user: state.getUser,
});

class MarketingConsentSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
    };
  }

  handleChange = (e, marketingConsent) => {
    if (marketingConsent) {
      this.changeMarketingConsentSetting(true);
    } else {
      this.toggleDialogOpen();
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.changeMarketingConsentSetting(false);
    this.toggleDialogOpen();
  };

  changeMarketingConsentSetting = shouldSend => {
    this.props.actions.changeMarketingConsentSetting({
      marketingConsent: shouldSend,
    });
  };

  toggleDialogOpen = () => {
    this.setState({ dialogOpen: !this.state.dialogOpen });
  };

  render() {
    return (
      <>
        {this.props.user && (
          <Switch
            checked={!!this.props.user.data.marketingConsent}
            onChange={this.handleChange}
            name='marketingConsent'
            color='primary'
          />
        )}

        <Dialog open={this.state.dialogOpen} onClose={this.toggleDialogOpen}>
          <form onSubmit={this.handleSubmit}>
            <DialogTitle>Przesyłanie informacji marketingowych</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Czy na pewno chcesz zrezygnować z otrzymywania informacji o
                promocjach i nowych ofertach?
              </DialogContentText>
              <DialogContentText>
                Pamiętaj, że zawsze możesz zmienić ustawienia by być na
                bieżąco.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color='primary' onClick={this.toggleDialogOpen}>
                Anuluj
              </Button>
              <Button type='submit'>Wycofuję zgodę</Button>
            </DialogActions>
          </form>
        </Dialog>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketingConsentSetting);
