import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../actions';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  DialogActions,
  Grid,
  Alert
} from '@mui/material';
import { red } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

const partnerTypesOptions = [
  { text: 'Osoba fizyczna', value: 'individual' },
  {
    text: 'Jednoosobowa działalność gospodarcza',
    value: 'sole_proprietorship',
  },
  { text: 'Spółka lub fundacja', value: 'company' },
];

function mapStateToProps(state) {
  return {
    createPartner: state.createPartner,
  };
}

const PREFIX = "PartnerCreator";
const classes = {
  resetButton: `${PREFIX}-resetButton`,
}
const Root = styled('div')(({theme}) => ({
  [`& .${classes.resetButton}`]: {
    color: red[400],
    backgroundColor: theme.palette.getContrastText(red[400]),
    borderColor: red[400],
    '&:hover': {
      borderColor: theme.palette.getContrastText(red[400]),
    },
  },
}));

class PartnerCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partnerType: '',
      errorMessage: '',
      isOpen: false,
    };
  }

  canPartnerTypeBeChanged() {
    const nonupdatableAttributes = [
      'firstName',
      'lastName',
      'companyName',
      'personalIdentityNumber',
      'taxIdNumber',
      'regon',
    ];
    const details = this.props.partner?.details;
    if (details && nonupdatableAttributes.some(attr => details[attr])) {
      return false;
    }

    return true;
  }

  toggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      partnerType: this.props.partner.type,
      errorMessage: '',
    });
  };

  componentDidUpdate(prevProps) {
    const { createPartner } = this.props;
    if (createPartner !== prevProps.createPartner) {
      if (createPartner.data) {
        window.location.href = createPartner.data.kycUrl;
      } else if (createPartner.reason) {
        this.setState({ errorMessage: createPartner.reason });
      }
    }
  }

  handleCreatePartner = e => {
    e.preventDefault();
    if (this.canCreatePartner()) {
      this.props.actions.createPartner({ type: this.state.partnerType });
    }
  };

  canCreatePartner = () => {
    return !!this.state.partnerType;
  };

  handleChangePartnerType = ({ target }) =>
    this.setState({ partnerType: target.value });

  renderActionButtons() {
    const { partner } = this.props;

    if (!partner.type)
      return (
        <Button onClick={this.toggleOpen} variant='outlined' color='primary'>
          Rozpocznij proces weryfikacji
        </Button>
      );

    if (['accepted', 'rejected'].includes(partner.kycStatus))
      return (
        <Button
          onClick={this.toggleOpen}
          className={classes.resetButton}
          variant='outlined'
        >
          Zweryfikuj ponownie
        </Button>
      );

    return (
      <Grid container justifyContent='center' spacing={2} direction='row'>
        <Grid item>
          <a href={partner.kycUrl}>
            <Button variant='outlined' color='primary'>
              Kontynuuj
            </Button>
          </a>
        </Grid>
        <Grid item>
          <Button
            onClick={this.toggleOpen}
            className={classes.resetButton}
            variant='outlined'
          >
            Zweryfikuj ponownie
          </Button>
        </Grid>
      </Grid>
    );
  }

  render() {
    return (
      <Root>
        {this.renderActionButtons()}
        <Dialog open={this.state.isOpen} onClose={this.toggleOpen}>
          <form onSubmit={this.handleCreatePartner}>
            <DialogTitle>Rozpocznij proces weryfikacji konta</DialogTitle>
            <DialogContent>
              <Box textAlign='center'>
                {this.canPartnerTypeBeChanged() && (
                  <FormControl
                    variant='outlined'
                    sx={{m: 1, minWidth: 240}}
                  >
                    <InputLabel id='partner_type'>Typ konta</InputLabel>
                    <Select
                      labelId='partner_type'
                      value={this.state.partnerType}
                      label='Typ konta'
                      placeholder='Typ konta'
                      name='provider'
                      onChange={this.handleChangePartnerType}
                      minWidth={120}
                    >
                      {partnerTypesOptions.map(({ value, text }) => (
                        <MenuItem key={value} value={value}>
                          {text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <DialogContentText>
                  W następnym kroku zostaniesz przekierowany na stronę usługi
                  FiberID
                </DialogContentText>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button type='button' onClick={this.toggleOpen}>
                Anuluj
              </Button>
              <Button
                color='primary'
                variant='contained'
                disableElevation
                disabled={!this.canCreatePartner()}
                type='submit'
              >
                Dalej
              </Button>
            </DialogActions>
            {this.state.errorMessage && (
              <Alert severity='error'>{this.state.errorMessage}</Alert>
            )}
          </form>
        </Dialog>
      </Root>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerCreator);
