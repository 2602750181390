import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../actions';
import {
  Box,
  Typography,
  Button,
  Chip,
  Grid,
  Container,
  Alert, 
  AlertTitle
} from '@mui/material';
import { navigate } from 'gatsby-link';
import PartnerCreator from './PartnerCreator';
import PartnerDetails from './PartnerDetails';
import MarketingConsentSetting from './MarketingConsentSetting';
import BasePageContent from '../../utils/BasePageContent';
import FacebookButton from '../../utils/FacebookButton';
import { SHOW_FACEBOOK } from '../../../constants';

const types = {
  individual: 'Osoba fizyczna',
  sole_proprietorship: 'Jednoosobowa działalność gospodarcza',
  company: 'Spółka / fundacja',
};

const mapRejectionReason = {
  tax_id_number_not_unique:
    'Użytkownik o wskazanym w trakcie weryfikacji numerze NIP istnieje.',
  personal_identity_number_not_unique:
    'Użytkownik o wskazanym w trakcie weryfikacji numerze PESEL istnieje.',
};

const usePreviouslyVerifiedAccount =
  'Jeśli posiadasz dostęp do wcześniej zweryfikowanego konta - pozostań przy posługiwaniu się nim.';

function mapStateToProps(state) {
  return {
    partner: state.getPartner,
    createPartner: state.createPartner,
    user: state.getUser,
  };
}

class Identity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      partner: null,
    };
  }

  componentDidMount() {
    this.props.actions.getUser();
    this.props.actions.getPartner();
  }

  componentDidUpdate(prevProps) {
    const { partner } = this.props;
    if (partner !== prevProps.partner) {
      this.setPartnerData(partner);
    }
  }

  setPartnerData = partner => {
    this.setState({ partner: partner.data, loading: false });
  };

  getPartnerKycStatus = () => {
    return this.state.partner?.kycStatus;
  };

  getRejectionMessage = () => {
    const { rejectionReason } = this.state.partner;
    const message = mapRejectionReason[rejectionReason] || rejectionReason;

    if (
      [
        'tax_id_number_not_unique',
        'personal_identity_number_not_unique',
      ].includes(rejectionReason)
    ) {
      return (
        <>
          {message}
          <br />
          {usePreviouslyVerifiedAccount}
        </>
      );
    }

    return <>{message}</>;
  };

  renderStatusAlert() {
    const { partner, loading } = this.state;
    if (loading) return <></>;
    if (!partner.type) {
      return (
        <Alert severity='info' icon={false}>
          <Typography>
            Przed utworzeniem pierwszego zlecenia niezbędna jest weryfikacja
            konta.
          </Typography>
        </Alert>
      );
    }

    const kycStatus = this.getPartnerKycStatus();

    if (kycStatus === 'accepted') {
      if (partner.hasDefaultAccount) {
        return (
          <Alert severity='success'>Weryfikacja zakończona sukcesem</Alert>
        );
      }
      return (
        <>
          <Alert severity='info'>
            <Typography>
              Pozostał ostatni krok przed utworzeniem Twojego pierwszego
              zlecenia.
            </Typography>
            <Typography>
              Wskaż numer konta, na które chcesz otrzymywać przekazane przez
              płatnika środki.
            </Typography>
          </Alert>
          <Box pt={2} textAlign='center'>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => navigate('/user/accounts')}
            >
              Ustaw domyślne konto bankowe
            </Button>
          </Box>
        </>
      );
    }

    if (kycStatus === 'rejected') {
      return (
        <Alert severity='error'>
          <AlertTitle>Weryfikacja konta nie powiodła się</AlertTitle>
          {this.getRejectionMessage()}
        </Alert>
      );
    }

    return (
      <Alert severity='info'>Proces weryfikacji nie został ukończony.</Alert>
    );
  }

  render() {
    const { partner, loading } = this.state;
    const isKycAccepted = this.getPartnerKycStatus() === 'accepted';
    return (
      <BasePageContent title='Dane konta' loading={loading}>
        <Container maxWidth={false}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              {this.renderStatusAlert()}
            </Grid>
            {!isKycAccepted && (
              <Grid container item xs={12} justifyContent='center'>
                <PartnerCreator partner={partner} />
              </Grid>
            )}
            <Grid container item spacing={2}>
              {partner?.type && (
                <Grid container item spacing={2}>
                  <Grid item xs={6}>
                    <b>Typ konta</b>
                  </Grid>
                  <Grid item>
                    <Chip
                      label={types[partner.type]}
                      variant='outlined'
                      color='primary'
                      size='small'
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container item spacing={2}>
                <Grid item xs={6}>
                  <b>Adres email</b>
                </Grid>
                <Grid item>{this.props.user?.data.email}</Grid>
              </Grid>
            </Grid>
            {isKycAccepted && (
              <>
                <PartnerDetails type={partner.type} details={partner.details} />
                <Grid item xs={12}>
                  <PartnerCreator partner={partner} />
                </Grid>
              </>
            )}
            <Grid container item alignItems='center' spacing={2}>
              {facebookPanel(this.props.user?.data.facebook)}
              <Grid item xs={6}>
                <b>Zgoda na przesyłanie informacji marketingowych</b>
              </Grid>
              <Grid item>
                <MarketingConsentSetting />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </BasePageContent>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Identity);


const facebookPanel = (facebook) => {
  if(SHOW_FACEBOOK){
    return (
      <>
        <Grid item xs={6}>
          <b>Konto Facebook</b>
        </Grid>
        <Grid item>
          {facebook ? (
            <Chip label='Połączono' variant='outlined' />
          ) : (
            <FacebookButton
              content='Połącz z kontem Facebook'
              type='link'
            />
          )}
        </Grid>
      </>
    )
  }
}