import React from 'react';
import Layout from '../../templates/layout';
import UserIdentity from '../../components/user/identity/Identity';

const IdentityPage = () => (
  <Layout >
    <UserIdentity />
  </Layout>
);

export const Head = () => <title>Dane osobowe | Zapłatomat</title>;


export default IdentityPage;
