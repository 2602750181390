import React from 'react';
import { Grid, Typography } from '@mui/material';

const keys = {
  firstName: 'Imię',
  lastName: 'Nazwisko',
  personalIdentityNumber: 'PESEL',
  idNumber: 'Nr dokumentu tożsamości',
  idExpirationDate: 'Data ważności dokumentu tożsamości',
  taxIdNumber: 'NIP',
  regon: 'REGON',
  address: 'Adres',
  phoneNumber: 'Nr telefonu',
  postalCode: 'Kod pocztowy',
  city: 'Miasto',
  country: 'Kraj',
  companyName: 'Nazwa firmy',
  companyAddress: 'Adres',
  companyPostalCode: 'Kod pocztowy',
  companyCity: 'Miasto',
  companyCountry: 'Kraj',
};

const companyKeys = [
  'companyAddress',
  'companyPostalCode',
  'companyCity',
  'companyCountry',
];

const mapKeyName = (key, type) => {
  let value = keys[key];
  if (type === 'sole_proprietorship' && companyKeys.includes(key)) {
    return `${value} (firma)`;
  }

  return value;
};

const getFormattedValue = (key, value) => {
  if(key === 'country' || key === 'companyCountry') {
    return value === 'PL' ? 'POLSKA' : value;
  }

  return value;
}

const PartnerDetails = ({ details, type }) => (
  <Grid container item spacing={2}>
    <Grid container item>
      <Typography variant='h6'>
        Informacje o użytkowniku
      </Typography>
    </Grid>
    <Grid container item spacing={2}>
      {Object.entries(details).map(
        ([key, value]) =>
          value && (
            <Grid key={key} container item spacing={2}>
              <Grid item xs={6}>
                <b>{mapKeyName(key, type)}</b>
              </Grid>
              <Grid item>{getFormattedValue(key, value)}</Grid>
            </Grid>
          )
      )}
    </Grid>
  </Grid>
);

export default PartnerDetails;
